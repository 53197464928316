import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import translationEN from './translationENG.json';
import translationUKR from './translationUKR.json';

// Define the resources
const resources = {
  en: {
    translation: translationEN
  },
  ukr: {
    translation: translationUKR
  }
};

i18n
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Pass the i18n instance to react-i18next.
  .init({
    resources, // Resources for the translations
    fallbackLng: 'en', // Fallback language if the detected language is not available
    interpolation: {
      escapeValue: false // React already escapes values
    }
  });

export default i18n;
