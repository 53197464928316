import React, { useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import { MenuList } from "../helper/MenuList";
import "../components/styles/Description.css";
import { useTranslation } from "react-i18next";

function Description() {
  const { t } = useTranslation();

  const { id } = useParams();
  const navigate = useNavigate();
  const menuItem = MenuList.find((item) => item.id === parseInt(id));

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAnimal, setSelectedAnimal] = useState("all");
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");
  const [expandedAnimal, setExpandedAnimal] = useState(null); // To track expanded animal

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };
 const handleDownload = () => {
  if (menuItem.pdfFile) {
    const pdfPath = `/${menuItem.pdfFile}`; // Ensure it starts with "/"
    const link = document.createElement("a");
    link.href = pdfPath;
    link.setAttribute("download", menuItem.pdfFile.split("/").pop()); // Extract filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    console.error("PDF file path is missing!");
  }
};

  const handleSearchClick = () => {
    navigate("/menu", {
      state: {
        searchQuery: searchTerm,
        selectedCategory,
        selectedAnimal,
        activeIngredient,
        pharmaceuticalForm,
      },
    });
  };

  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
  };

  if (!menuItem) {
    return <h2>{t("Item not found")}</h2>;
  }
 const handleAnimalToggle = (animal) => {
    setExpandedAnimal(expandedAnimal === animal ? null : animal); // Toggle expand/collapse
  };
  return (
      <div className="descriptionPage">
        <div className="productDetails">
          <div className={"title_image"}>
            <h1>{t(menuItem.nameText)}</h1>
            <img src={menuItem.image} alt={t(menuItem.nameText)} className="productImage"/>
          </div>
          <div className="productExtraInfo">
            <p>
              <strong>{t("Category")}:</strong> {t(menuItem.categoryText)}
            </p>

            <div className="productInfo">
              {/* Dynamically Render Details */}
              {menuItem.details &&
                  Object.entries(menuItem.details).map(([key, value]) => (
                      <p key={key}>
                        <strong>{t(key)}:</strong> {t(value)}
                      </p>
                  ))}
            </div>
            <div className="methodOfUse">

              {menuItem.methodOfUseForEachAnimal && (
                  <div>
                    <h2>{t("bisporin.methodOfUseWord")}</h2>
                    {Object.entries(menuItem.methodOfUseForEachAnimal).map(([animal, methods]) => (
                        <div key={animal} className="animalMethod">
                          <h3
                              className="animalHeader"
                              onClick={() => handleAnimalToggle(animal)}
                              style={{cursor: "pointer", color: "#007BFF"}}
                          >
                            {t(animal)} {expandedAnimal === animal ? "▼" : "▶"}
                          </h3>
                          {expandedAnimal === animal && (
                              <ul className="animalMethodList">
                                {Object.entries(methods).map(([key, value]) => (
                                    <li key={key}>{t(value)}</li>
                                ))}
                              </ul>
                          )}
                        </div>
                    ))}
                  </div>
              )}
            </div>
            <div className="productDownloadRegistrationCert">
              <button onClick={handleDownload} className="productDownloadRegistrationCert">
                {t("Download Product Registration")}
              </button>
            </div>
            <div className="productDownloadRegistrationCert">
              <Link to="/contact" className="productDownloadRegistrationCert">
                {t("Check availability")}
              </Link>
            </div>
          </div>

        </div>

        <div className="sidebar">
          <h2>{t("Search the Product")}</h2>
          <label>
            {t("Animal")}:
            <select
                value={selectedAnimal}
                onChange={(e) => setSelectedAnimal(e.target.value)}
            >
              <option value="all">{t("another")}</option>

              <option value="pig">{t("Pig")}</option>
              <option value="cattle">{t("Cattle")}</option>

              <option value="poultry">{t("Poultry")}</option>


              <option value="rabbit">{t("Rabbit")}</option>

              <option value="rodent">{t("Rodents")}</option>

            </select>
          </label>
          <label>
            {t("Category")}:
            <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="all">{t("All")}</option>
              <option value="vaccines">{t("Vaccines")}</option>
              <option value="diagnostics">{t("Diagnostics")}</option>
              <option value="rodenticides">{t("Rodenticides")}</option>
              <option value="test-kits">{t("Test Kits")}</option>
              <option
                  value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
              <option value="experimental-drugs">{t("Experimental Drugs")}</option>

            </select>
          </label>
          <label>
            {t("Product Name")}:
            <input
                type="text"
                placeholder={t("Enter product name")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
          </label>
          <label>
            {t("Active Ingredient")}:
            <input
                type="text"
                placeholder={t("Enter active ingredient")}
                value={activeIngredient}
                onChange={(e) => setActiveIngredient(e.target.value)}
            />
          </label>
          <label>
            {t("Pharmaceutical Form")}:
            <input
                type="text"
                placeholder={t("Enter pharmaceutical form")}
                value={pharmaceuticalForm}
                onChange={(e) => setPharmaceuticalForm(e.target.value)}
            />
          </label>
          <button onClick={handleReset} style={{marginBottom: "10px"}}>
            {t("Reset Search")}
          </button>
          <button onClick={handleSearchClick}>{t("Search")}</button>
        </div>
      </div>
  );
}

export default Description;
