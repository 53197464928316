import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import "../components/styles/Feedback.css"; // Import the corresponding CSS

const Feedback = () => {
    const {t} = useTranslation();
    const [review, setReview] = useState("");
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false); // State for loading
    const [message, setMessage] = useState(""); // State for feedback message
    const [messageType, setMessageType] = useState(""); // "success" or "error"

    const handleReviewSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Indicate loading state
        setMessage("");   // Clear any previous messages

        try {
            const response = await fetch("http://localhost:5000/send-feedback", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({name, message: review}), // Send name and review
            });

            if (response.ok) {
                setMessageType("success");
                setName("");
                setReview("");
            } else {


                setMessageType("error");

            }
        } catch (error) {
            console.error("Error:", error);
           setMessageType("error");
        } finally {
            setLoading(false); // Stop loading state
        }
    };

    return (
        <div className="feedback-container">
            <title>feedback.title</title>
            <h1 className="feedback-title">{t("feedback.title")}</h1>
            <p className="feedback-intro">{t("feedback.intro")}</p>

            {/* Customer Reviews Section */}
            <section className="feedback-reviews">
                <h2 className="feedback-section-title">{t("feedback.whatCustomersSay")}</h2>
                <div className="feedback-pdf-list">
                    {[...Array(9).keys()].map((index) => {
                        const pdfName = `4-${index + 1}.pdf`;
                        return (
                            <a
                                key={index}
                                href={`/pdf/pdfFeedback/${pdfName}`}
                                download={pdfName}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="feedback-pdf-link"
                            >
                                {t("feedback.downloadPDF", {pdfName})}
                            </a>
                        );
                    })}
                </div>
            </section>

            {/* Join Our Community Section */}
            <section className="feedback-community">
                <h2 className="feedback-section-title">{t("feedback.joinCommunity")}</h2>
                <p className="feedback-community-text">{t("feedback.shareExperience")}</p>
            </section>

            {/* Review Submission Form */}
            <section className="feedback-form">
                <h2 className="feedback-section-title">{t("feedback.submitReview")}</h2>
                {message && <p className="feedback-message">{message}</p>}
                {loading ? (
                    <p>{t("feedback.loadingMessage")}</p> // Show loading message or spinner
                ) : (
                    <form onSubmit={handleReviewSubmit} className="feedback-form-fields">
                        <label htmlFor="name" className="feedback-form-label">{t("feedback.name")}</label>
                        <input
                            type="text"
                            id="name"
                            className="feedback-form-input"
                            placeholder={t("feedback.namePlaceholder")}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        <label htmlFor="review" className="feedback-form-label">{t("feedback.review")}</label>
                        <textarea
                            id="review"
                            className="feedback-form-textarea"
                            placeholder={t("feedback.reviewPlaceholder")}
                            value={review}
                            onChange={(e) => setReview(e.target.value)}
                            required
                        ></textarea>

                        {/* Success or Error Message */}
                        {messageType === "success" && (
                            <div className="feedback-message success">
                                {t("feedback.thankYouMessage")}
                            </div>
                        )}
                        {messageType === "error" && (
                            <div className="feedback-message error">
                                {t("feedback.errorMessage")}
                            </div>
                        )}
                        <button type="submit" className="feedback-form-button">{t("feedback.submit")}</button>
                    </form>
                )}


            </section>
        </div>
    );
};

export default Feedback;
