import React, { useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import "../components/styles/Description.css";
import { useTranslation } from "react-i18next";
import Cat from "../assets/ла-сота.jpg";

function Lasota() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAnimal, setSelectedAnimal] = useState("all");
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };
const handleDownload = () => {
  const pdfPath = "/pdf/pdf_vkladki/ЛА-СОТА.PDF"; // Correct path to the file
  const link = document.createElement('a');
  link.href = encodeURI(pdfPath);  // Encode the URI to handle special characters
  link.setAttribute('download', pdfPath.split('/').pop()); // Extracts the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  const handleSearchClick = () => {
    navigate("/menu", {
      state: {
        searchQuery: searchTerm,
        selectedCategory,
        selectedAnimal,
        activeIngredient,
        pharmaceuticalForm,
      },
    });
  };

  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
  };

  return (
      <div className="descriptionPage">
          <div className="productDetails">
              <div className="title_image">
                  <h1>{t("Virus Vaccine La-Sota")}</h1>
                  <img src={Cat} alt="Lasota Vaccine" className="productImage"/>
              </div>
              <div className="productExtraInfo">
                  <p>
                      <strong>{t("Full Name:")}</strong> {t("Dry viral vaccine against Newcastle disease in birds from the 'La-Sota' strain.")}
                  </p>
                  <ul>
                      <li>{t("Dose")}: 100 {t("doses")} - {t("Price")}: 126.00 UAH - {t("Min Order")}:
                          {t("1 vial")} - {t("Availability")}: {t("Not in stock")}</li>
                      <li>{t("Dose")}: 375 {t("doses")} - {t("Price")}: 228.75 UAH - {t("Min Order")}:
                          {t("1 vial")} - {t("Availability")}: {t("Not in stock")}</li>
                      <li>{t("Dose")}: 500 {t("doses")} - {t("Price")}: 255.00 UAH - {t("Min Order")}:
                          {t("1 vial")} - {t("Availability")}: {t("Not in stock")}</li>
                  </ul>
                  <h3>{t("Composition")}</h3>
                  <p><strong>{t("Active ingredients:")}</strong></p>
                  <ul>
                      <li>
                          {t(
                              "Virus-containing embryonic fluid from chicken embryos infected with the production strain 'La-Sota' of Newcastle disease virus, with an activity of no less than 10⁹.⁰ EID₅₀/cm³."
                          )}
                      </li>
                      <li>
                          {t(
                              "One dose of the vaccine contains Newcastle disease virus, strain 'La-Sota' ≥10⁶.⁰ EID₅₀/cm³."
                          )}
                      </li>
                      <li>
                          {t(
                              "Excipients: protective medium based on peptone and skim milk."
                          )}
                      </li>
                  </ul>

                  <h3>{t("Pharmaceutical Form")}</h3>
                  <p>
                      {t(
                          "Lyophilizate in the form of a dry porous mass or tablet with a homogeneous fine-grained structure of light beige or cream color."
                      )}
                  </p>

                  <h3>{t("Immunobiological Properties")}</h3>
                  <p>
                      {t(
                          "Immunity in birds forms 7-8 days after aerosol vaccination and 8-10 days after vaccination by drinking or intranasal administration."
                      )}
                  </p>
                  <p>
                      {t(
                          "The results of each immunization are monitored 14-16 days later by serological examination of blood serum using the hemagglutination inhibition (HI) test. At least 25-30 blood samples from birds in the poultry house are tested."
                      )}
                  </p>
                  <p>
                      {t(
                          "In favorable farms, birds are vaccinated and revaccinated if 10-12% or more of blood samples have antibody titers below 1:8."
                      )}
                  </p>
                  <p>{t("The vaccine does not have therapeutic properties.")}</p>

                  <h3>{t("Target Species")}</h3>
                  <p>{t("Chickens, quails, pigeons.")}</p>

                  <h3>{t("Indications for Use")}</h3>
                  <p>
                      {t(
                          "For prophylactic vaccination of adult birds and chicks against Newcastle disease, regardless of their breed or productivity. Prior to vaccination, the entire population is inspected, and only clinically healthy birds are vaccinated. Birds are vaccinated from 15 days of age and older."
                      )}
                  </p>
                  <p>{t("Birds are vaccinated:")}</p>
                  <ul>
                      <li>
                          {t(
                              "In favorable farms at the age of 15-20, 45-60, and 140-160 days, and then every six months."
                          )}
                      </li>
                      <li>
                          {t(
                              "In unfavorable farms at the age of 10-15, 35-40, and 120-140 days, and then every six months."
                          )}
                      </li>
                  </ul>
                  <p>
                      {t(
                          "Specific vaccination and revaccination schedules in favorable and unfavorable farms are determined based on the state of immune tension."
                      )}
                  </p>
                  <p>
                      {t(
                          "If immunization is conducted for the first time in farms favorable for Newcastle disease, all birds starting from 15 days of age are vaccinated simultaneously. In unfavorable farms, if chicks are obtained from unvaccinated parent stock, the first vaccination is conducted at 5-6 days of age."
                      )}
                  </p>
                  <p>
                      {t(
                          "In favorable farms, the vaccine is administered through drinking water, by aerosol, or intranasally. In unfavorable farms, birds are vaccinated using aerosol or intranasal methods."
                      )}
                  </p>
                  <p>
                      {t(
                          "After lifting quarantine and recovery of farms from Newcastle disease, subsequent immunization within 12 months is conducted according to the schedule for unfavorable farms."
                      )}
                  </p>

                  <h3>{t("Contraindications")}</h3>
                  <p>{t("Do not vaccinate sick birds.")}</p>

                  <h3>{t("Precautions for Use")}</h3>
                  <p>
                      {t(
                          "The product must not be used after the expiration date or if storage temperature conditions are violated."
                      )}
                  </p>

                  <h3>{t("Interactions with Other Products")}</h3>
                  <p>{t("Do not mix with other medicinal or preventive products.")}</p>

                  <h3>{t("Special Precautions During Egg Production")}</h3>
                  <p>{t("According to the generally accepted scheme.")}</p>

                  <h3>{t("Method of Administration and Dosage")}</h3>
                  <p>
                      {t(
                          "The vaccine is administered to birds using one of the following methods: intranasally, enterally, or by large-droplet spray. The vaccine is dissolved in clean, cooled boiled water at room temperature."
                      )}
                  </p>

                  <h3>{t("Intranasal Vaccination Method")}</h3>
                  <p>
                      {t(
                          "For intranasal administration, the contents of vials containing 100, 200, 250, 300, 350, 375, 500, 750, 1000, 1500, or 3000 doses are dissolved respectively in 10, 20, 25, 30, 35, 37.5, 50, 75, 100, 150, or 300 cm³ of clean, cooled boiled water."
                      )}
                  </p>
                  <p>
                      {t(
                          "The diluted vaccine is instilled with a pipette into the bird's nostril, two drops (0.1 cm³) per nostril, regardless of age and species. While instilling into one nostril, the other is closed with a finger to allow deeper penetration into the nasal cavity."
                      )}
                  </p>

                  <h3>{t("Enteral Vaccination Method")}</h3>
                  <p>{t("Before vaccination, birds are deprived of water for 3 hours.")}</p>
                  <p>
                      {t(
                          "The number of doses must correspond to the number of birds to be vaccinated. The vaccine is dissolved in boiled, cooled water depending on the age of the birds."
                      )}
                  </p>
                  <table>
                      <thead>
                      <tr>
                          <th>{t("Bird Age")}</th>
                          <th>{t("Number of Individuals")}</th>
                          <th>{t("Amount of Water (L)")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                          <td>{t("Up to 21 days")}</td>
                          <td>1,000</td>
                          <td>15</td>
                      </tr>
                      <tr>
                          <td>{t("7 weeks")}</td>
                          <td>1,000</td>
                          <td>20</td>
                      </tr>
                      <tr>
                          <td>{t("14 weeks")}</td>
                          <td>1,000</td>
                          <td>30</td>
                      </tr>
                      <tr>
                          <td>{t("20 weeks")}</td>
                          <td>1,000</td>
                          <td>40</td>
                      </tr>
                      </tbody>
                  </table>

                  <p>
                      {t(
                          "After providing diluted vaccine, feed and water are recommended to be given 1.5 hours later. Drinkers must be pre-washed with water without the use of disinfectants. The use of nickel and zinc drinkers is prohibited. The vaccine is administered once in the morning at a rate of 15.0 cm³ per bird up to 21 days old, 20.0 cm³ per bird at 7 weeks of age, 30.0 cm³ per bird older than 14 weeks, and 40.0 cm³ per bird at 20 weeks of age."
                      )}
                  </p>

                  <h3>{t("Aerosol Method")}</h3>
                  <p>
                      {t(
                          "The contents of one vial (100 doses) are dissolved in 10-15 cm³ of boiled, cooled water. For 300 doses, dissolve in 30-45 cm³; for 200 doses, in 20-30 cm³; for 350 doses, in 35-52.5 cm³; for 375 doses, in 37.5-56.25 cm³; for 1000 doses (500 doses), in 100-150 cm³ (50-75 cm³); for 1500 doses, in 150-225 cm³; and for 3000 doses, in 300-450 cm³."
                      )}
                  </p>
                  <p>
                      {t(
                          "The vaccine is administered to birds using the large-droplet spray method, which ensures constant pressure and uses a calibrated nozzle. The spray should be applied at a distance of 30-40 cm above the birds' heads. The approximate vaccination speed is 1,000 birds per minute."
                      )}
                  </p>

                  <h3>{t("Adverse Reactions")}</h3>
                  <p>
                      {t(
                          "Daily observation is conducted for 14 days after vaccination. Mild symptoms such as malaise, decreased appetite, or shortness of breath may appear in young birds and typically resolve by the 10th-12th day post-vaccination. Adult birds usually do not exhibit post-vaccination reactions. If birds in a latent stage of disease are vaccinated, mortality may occur."
                      )}
                  </p>

                  <h3>{t("Withdrawal Period")}</h3>
                  <p>{t("14 days.")}</p>

                  <h3>{t("Special Precautions for Handling Unused Product")}</h3>
                  <p>
                      {t(
                          "Vaccine vials with foreign substances, cracks, damaged packaging, or no labeling are rejected. Used vials and unused vaccine must be neutralized within 4 hours by boiling in a 2% soda solution for 1 hour."
                      )}
                  </p>

                  <h3>{t("Shelf Life")}</h3>
                  <p>{t("12 months.")}</p>

                  <h3>{t("Storage and Transportation Conditions")}</h3>
                  <p>
                      {t(
                          "Store in a dry, dark place inaccessible to children at a temperature of 2°C to 8°C. Transportation must comply with the cold chain conditions."
                      )}
                  </p>

                  <h3>{t("Packaging")}</h3>
                  <p>{t("Glass vials or ampoules containing 50-3,000 doses.")}</p>

                  <h3>{t("Dispensing Rules")}</h3>
                  <p>{t("By prescription.")}</p>

                  <h3>{t("Manufacturer and Location")}</h3>
                  <p>
                      {t(
                          "State Enterprise 'Sumy Biological Factory', 40021, Sumy, Lykhvynska Stinka Street, 25, Ukraine."
                      )}
                  </p>

                  <h3>{t("Additional Information")}</h3>
                  <p>
                      {t(
                          "If the product does not meet the package insert requirements or complications arise, the use of this series is immediately discontinued. Report the incident to the State Scientific Control Institute of Biotechnology and Strain Microorganisms (SSIBSM) and the supplier (manufacturer). Send three unopened vials of the series in question along with the claim to the address: 03151, Kyiv, Donetska Street, 30, SSIBSM."
                      )}
                  </p>
                  <div className="productDownloadRegistrationCert">
                      <button onClick={handleDownload} className="productDownloadRegistrationCert">
                          {t("Download Product Registration")}
                      </button>
                  </div>
                  <div className="productDownloadRegistrationCert">
                      <Link to="/contact" className="productDownloadRegistrationCert">
                          {t("Check availability")}
                      </Link>
                  </div>
              </div>
          </div>
          <div className="sidebar">
              <h2>{t("Search the Product")}</h2>
              <label>
                  {t("Animal")}:
                  <select
                      value={selectedAnimal}
                      onChange={(e) => setSelectedAnimal(e.target.value)}
                  >
                      <option value="all">{t("another")}</option>

                      <option value="pig">{t("Pig")}</option>
                      <option value="cattle">{t("Cattle")}</option>

                      <option value="poultry">{t("Poultry")}</option>


                      <option value="rabbit">{t("Rabbit")}</option>

                      <option value="rodent">{t("Rodents")}</option>

                  </select>
              </label>
              <label>
                  {t("Category")}:
                  <select
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                      <option value="all">{t("All")}</option>
                      <option value="vaccines">{t("Vaccines")}</option>
                      <option value="diagnostics">{t("Diagnostics")}</option>
                      <option value="rodenticides">{t("Rodenticides")}</option>
                      <option value="test-kits">{t("Test Kits")}</option>
                      <option
                          value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
                      <option value="experimental-drugs">{t("Experimental Drugs")}</option>

                  </select>
              </label>
              <label>
                  {t("Product Name")}:
                  <input
                      type="text"
                      placeholder={t("Enter product name")}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                  />
              </label>
              <label>
                  {t("Active Ingredient")}:
                  <input
                      type="text"
                      placeholder={t("Enter active ingredient")}
                      value={activeIngredient}
                      onChange={(e) => setActiveIngredient(e.target.value)}
                  />
              </label>
              <label>
                  {t("Pharmaceutical Form")}:
                  <input
                      type="text"
                      placeholder={t("Enter pharmaceutical form")}
                      value={pharmaceuticalForm}
                      onChange={(e) => setPharmaceuticalForm(e.target.value)}
                  />
              </label>
              <button onClick={handleReset} style={{marginBottom: "10px"}}>
                  {t("Reset Search")}
              </button>
              <button onClick={handleSearchClick}>{t("Search")}</button>
          </div>
      </div>
  );
}

export default Lasota;
