import React, { useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import "../components/styles/Description.css";
import { useTranslation } from "react-i18next";
import Cat from "../assets/ДЗПТ2.jpg";

function Deratez() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAnimal, setSelectedAnimal] = useState("all");
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleSearchClick = () => {
    navigate("/menu", {
      state: {
        searchQuery: searchTerm,
        selectedCategory,
        selectedAnimal,
        activeIngredient,
        pharmaceuticalForm,
      },
    });
  };
const handleDownload = () => {
  const pdfPath = "/pdf/pdf_vkladki/ДЗПТ-2.PDF"; // Correct path to the file
  const link = document.createElement('a');
  link.href = encodeURI(pdfPath);  // Encode the URI to handle special characters
  link.setAttribute('download', pdfPath.split('/').pop()); // Extracts the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
  };

  return (
      <div className="descriptionPage">
          <div className="productDetails">
              <div className="title_image">
                  <h1>{t("A disinfectant containing glutaraldehyde and water")}</h1>
                  <img src={Cat} alt="Rodent Control Product" className="productImage"/>
              </div>
              <div className="productExtraInfo">
                  <h3>{t('Composition')}</h3>
                  <p>
                      {t('A disinfectant containing glutaraldehyde and water, additionally includes an anionic surfactant - sodium dodecyl sulfate, and essential oil as a fragrance.')}
                  </p>

                  <h3>{t('Pharmaceutical Form')}</h3>
                  <p>
                      {t('Solution for disinfection.')}
                  </p>
                  <p><strong>{t("Price per liter")}</strong>: 390 {t("UAH")}</p>
                  <p><strong>{t("Minimum order")}</strong>: 1 {t("liter")}</p>
                  <p><strong>{t("Availability")}</strong>: {t("Out of stock")}</p>
                  <h3>{t('Action')}</h3>
                  <p>
                      {t('Glutaraldehyde (1.0-2.0%) is an active ingredient with bactericidal effects on microorganisms; sodium dodecyl sulfate (0.25-1.0%) is an anionic surfactant providing cleaning properties; essential oil (0.05%) serves as a fragrance, giving the preparation deodorizing properties.')}
                  </p>
                  <p>
                      {t('The disinfectant "DZPT-2" meets modern requirements for water-based disinfectants. It is safe to use, foams well, has deodorizing properties, rinses off easily with water, and effectively acts against mycobacteria and microscopic fungi.')}
                  </p>

                  <h3>{t('Indications for Use')}</h3>
                  <p>
                      {t('For wet disinfection of livestock facilities in farms, both free from and affected by infectious diseases.')}
                  </p>

                  <h3>{t('Name, Location, and Activity Address of Registration Certificate Holder')}</h3>
                  <p>
                      {t('National Scientific Center "Institute of Experimental and Clinical Veterinary Medicine", 61023, Ukraine, Kharkiv, Pushkinska St., 83, phone/fax (0577) 07-20-44 / (0577) 04-10-90.')}
                  </p>

                  <h3>{t('Name and Address of Manufacturer')}</h3>
                  <p>
                      {t('State Enterprise "Sumy Biological Factory", 40021, Ukraine, Sumy, Hamalia St., 25.')}
                  </p>
                  <div className="productDownloadRegistrationCert">
                      <button onClick={handleDownload} className="productDownloadRegistrationCert">
                          {t("Download Product Registration")}
                      </button>
                  </div>
                  <div className="productDownloadRegistrationCert">
                      <Link to="/contact" className="productDownloadRegistrationCert">
                          {t("Check availability")}
                      </Link>
                  </div>
              </div>
          </div>
          <div className="sidebar">
              <h2>{t("Search the Product")}</h2>
              <label>
                  {t("Animal")}:
                  <select
                      value={selectedAnimal}
                      onChange={(e) => setSelectedAnimal(e.target.value)}
                  >
                      <option value="all">{t("another")}</option>

                      <option value="pig">{t("Pig")}</option>
                      <option value="cattle">{t("Cattle")}</option>

                      <option value="poultry">{t("Poultry")}</option>


                      <option value="rabbit">{t("Rabbit")}</option>

                      <option value="rodent">{t("Rodents")}</option>

                  </select>
              </label>
              <label>
                  {t("Category")}:
                  <select
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                      <option value="all">{t("All")}</option>
                      <option value="vaccines">{t("Vaccines")}</option>
                      <option value="diagnostics">{t("Diagnostics")}</option>
                      <option value="rodenticides">{t("Rodenticides")}</option>
                      <option value="test-kits">{t("Test Kits")}</option>
                      <option
                          value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
                      <option value="experimental-drugs">{t("Experimental Drugs")}</option>

                  </select>
              </label>
              <label>
                  {t("Product Name")}:
                  <input
                      type="text"
                      placeholder={t("Enter product name")}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                  />
              </label>
              <label>
                  {t("Active Ingredient")}:
                  <input
                      type="text"
                      placeholder={t("Enter active ingredient")}
                      value={activeIngredient}
                      onChange={(e) => setActiveIngredient(e.target.value)}
                  />
              </label>
              <label>
                  {t("Pharmaceutical Form")}:
                  <input
                      type="text"
                      placeholder={t("Enter pharmaceutical form")}
                      value={pharmaceuticalForm}
                      onChange={(e) => setPharmaceuticalForm(e.target.value)}
                  />
              </label>
              <button onClick={handleReset} style={{marginBottom: "10px"}}>
                  {t("Reset Search")}
              </button>
              <button onClick={handleSearchClick}>{t("Search")}</button>
          </div>
      </div>
  );
}

export default Deratez;
