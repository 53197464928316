import React from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import "../components/styles/Directions.css"; // Import the corresponding CSS

const Directions = () => {
  const { t } = useTranslation(); // Hook to access translations

  return (
    <div className="dir-container">
      <h1 className="dir-title">{t("directions.title")}</h1>
      <p>
        <strong>{t("directions.addressLabel")}</strong> {t("directions.address")}
      </p>
      <p>{t("directions.welcomeMessage")}</p>

      <h2 className="dir-section-title">{t("directions.byCar")}</h2>
      <p>{t("directions.byCarDescription")}</p>
      <div className="dir-map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1253.452865547889!2d34.7912136851227!3d50.87755375006364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x412901da638394cf%3A0xc05772254fe4643c!2z0LLRg9C70LjRhtGPINCd0L7RgdGC0LDQvdC40YLRjCAyNSwg0KHQsNC70L7QstCwLCDQodCw0L3QsNGPINC60LDRgNC90YHQutCw!5e0!3m2!1sen!2sua!4v1695114093094!5m2!1sen!2sua"
          allowFullScreen=""
          loading="lazy"
          title={t("directions.mapTitle")}
        ></iframe>
      </div>

      <h2 className="dir-section-title">{t("directions.byTransport")}</h2>
      <div className="dir-transport">
        <p>{t("directions.nearestStop")}</p>
        <p>{t("directions.routesDescription")}</p>
        <ul className="dir-transport-list">
          <li>
            <strong>{t("directions.busesLabel")}</strong> {t("directions.buses")}
          </li>
          <li>
            <strong>{t("directions.trolleybusesLabel")}</strong> {t("directions.trolleybuses")}
          </li>
        </ul>
        <p>{t("directions.travelTime")}</p>
        <p>
          {t("directions.trackTransport")}
          <a
            href="http://gps.sumy.ua/"
            className="dir-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("directions.gpsLink")}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Directions;
