import React, { useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import "../components/styles/Description.css";
import { useTranslation } from "react-i18next";
import Cat from "../assets/СИБІРКА.jpg";

function Sibirka() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAnimal, setSelectedAnimal] = useState("all");
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleSearchClick = () => {
    navigate("/menu", {
      state: {
        searchQuery: searchTerm,
        selectedCategory,
        selectedAnimal,
        activeIngredient,
        pharmaceuticalForm,
      },
    });
  };

  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
  };
const handleDownload = () => {
  const pdfPath = "/pdf/pdf_vkladki/ЛИСТІВКА-ВКЛАДКА_СИБІРКА.PDF"; // Correct path to the file
  const link = document.createElement('a');
  link.href = encodeURI(pdfPath);  // Encode the URI to handle special characters
  link.setAttribute('download', pdfPath.split('/').pop()); // Extracts the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  return (
      <div className="descriptionPage">
          <div className="productDetails">
              <div className="title_image">
                  <h1>{t("Live Spore Vaccine Against Anthrax in Animals")}</h1>
                  <img src={Cat} alt="Anthrax Vaccine" className="productImage"/>
              </div>
              <div className="productExtraInfo">
                  <p>
                      <strong>{t("Product Name")}:</strong>{" "}
                      {t("Live Spore Vaccine Against Anthrax in Animals, Strain “SB,” Liquid")}
                  </p>
                  <div>
                      <p><strong>{t("Price per vial (30 doses)")}</strong>: 145.62 {t("UAH")}</p>
                      <p><strong>{t("Minimum order")}</strong>: 1 {t("vial")}</p>
                      <p><strong>{t("Availability")}</strong>: {t("Out of stock")}</p>
                  </div>
                  <div>
                      <p><strong>{t("Price per vial (50 doses)")}</strong>: 223.80 {t("UAH")}</p>
                      <p><strong>{t("Minimum order")}</strong>: 1 {t("vial")}</p>
                      <p><strong>{t("Availability")}</strong>: {t("In stock")}</p>
                  </div>

                  <h3>{t("Composition")}</h3>
                  <ul>
                      <li>{t("20-25 million viable spores of the avirulent Bacillus anthracis strain “SB.”")}</li>
                      <li>{t("Glycerin in phosphate buffer: 30 ± 3%.")}</li>
                  </ul>

                  <h3>{t("Pharmaceutical Form")}</h3>
                  <p>{t("A transparent, slightly opalescent whitish-yellow suspension with a slight whitish sediment that forms during storage and easily disperses into a homogeneous suspension upon shaking.")}</p>

                  <h3>{t("Immunobiological Properties")}</h3>
                  <p>{t("Used to create immunity in farm animals against anthrax. Immunity develops 10 days after vaccination and lasts for at least 12 months.")}</p>

                  <h3>{t("Animal Species")}</h3>
                  <p>{t("Horses, cattle, sheep, goats, fur animals, and pigs.")}</p>

                  <h3>{t("Indications for Use")}</h3>
                  <p>{t("The vaccine is used once for prophylactic and emergency immunization of horses, cattle, sheep, goats, fur animals, and pigs. Young animals, except foals, are vaccinated for the first time at the age of 3 months, while foals are vaccinated starting at 9 months. Revaccination of young animals is performed 6 months after the first vaccination. Adult animals are immunized once a year. Emergency vaccinations can be conducted at any time of the year regardless of acute infectious diseases in the herd.")}</p>

                  <h3>{t("Contraindications")}</h3>
                  <p>{t("Do not vaccinate animals under 3 months of age, debilitated or emaciated animals, and pregnant females in the last month of gestation. Vaccination of animals showing clinical illness or elevated body temperature is also prohibited.")}</p>

                  <h3>{t("Precautions for Use")}</h3>
                  <p>{t("Follow veterinary sanitary rules when using the vaccine. Only clinically healthy animals with normal body temperatures should be vaccinated. Do not use the vaccine within 14 days before slaughter. In the case of emergency slaughter, meat and by-products should be sent for technical processing or incinerated. Milk from animals with post-vaccination complications must be boiled for 2 hours before use.")}</p>

                  <h3>{t("Interaction with Other Products")}</h3>
                  <p>{t("Do not administer antibiotics or treatments for infections, infestations, or skin parasites within 10 days before or after vaccination.")}</p>

                  <h3>{t("Method of Administration and Dosage")}</h3>
                  <p>{t("The vaccine is administered subcutaneously:")}</p>
                  <ul>
                      <li>{t("Sheep and goats: 0.5 cm³ in the middle third of the neck or inner thigh.")}</li>
                      <li>{t("Horses, cattle, and fur animals: 1 cm³ in the middle third of the neck.")}</li>
                      <li>{t("Pigs: 1 cm³ in the inner thigh or behind the ear.")}</li>
                  </ul>
                  <p>{t("Young animals are vaccinated for the first time at 3 months of age (except foals, vaccinated at 9 months). Revaccination of young animals is done 6 months after the initial vaccination. Adult animals are vaccinated annually. Veterinary observation is required for 14 days post-vaccination.")}</p>

                  <h3>{t("Side Effects")}</h3>
                  <p>{t("Possible post-vaccination reactions include fever, swelling, or redness at the injection site. Such animals should be isolated and treated.")}</p>

                  <h3>{t("Withdrawal Period")}</h3>
                  <p>{t("14 days.")}</p>

                  <h3>{t("Special Warnings for Personnel")}</h3>
                  <p>{t("Adhere to hygiene practices. In case of accidental self-injection, seek immediate medical attention with the vaccine leaflet.")}</p>

                  <h3>{t("Storage and Transportation Conditions")}</h3>
                  <p>{t("The vaccine should be stored in a dry, dark, enclosed area at 2-10°C. Transportation should be carried out under cold chain conditions at 2-10°C. Store in a location out of reach of children. Do not freeze.")}</p>

                  <h3>{t("Packaging")}</h3>
                  <p>{t("Glass, sterile, hermetically sealed vials in the following sizes: 10 cm³, 20 cm³, 25 cm³, 30 cm³, 50 cm³, 100 cm³, 200 cm³.")}</p>
                  <h3>{t("Shelf Life")}</h3>
                  <p>{t("2 years from the manufacturing date.")}</p>

                  <h3>{t("Manufacturer")}</h3>
                  <p>{t("State Enterprise 'Sumy Biological Factory,' 40021 Sumy, Likhvinska Stinka St. 25, Ukraine, Tel/Fax: (0542) 61-70-32.")}</p>

                  <h3>{t("Additional Information")}</h3>
                  <p>{t("If the product does not meet the requirements of the leaflet or complications arise, stop using the batch immediately and inform the State Scientific Control Institute of Biotechnology and Microorganism Strains (SSCIB) and the supplier (manufacturer). Unopened vials from this batch must be sent to SSCIB, 03151 Kyiv, Donetska Street, 30.")}</p>
                  <div className="productDownloadRegistrationCert">
                      <button onClick={handleDownload} className="productDownloadRegistrationCert">
                          {t("Download Product Registration")}
                      </button>
                  </div>
                  <div className="productDownloadRegistrationCert">
                      <Link to="/contact" className="productDownloadRegistrationCert">
                          {t("Check availability")}
                      </Link>
                  </div>
              </div>

          </div>
          <div className="sidebar">
              <h2>{t("Search the Product")}</h2>
              <label>
                  {t("Animal")}:
                  <select
                      value={selectedAnimal}
                      onChange={(e) => setSelectedAnimal(e.target.value)}
                  >
                      <option value="all">{t("another")}</option>

                      <option value="pig">{t("Pig")}</option>
                      <option value="cattle">{t("Cattle")}</option>

                      <option value="poultry">{t("Poultry")}</option>


                      <option value="rabbit">{t("Rabbit")}</option>

                      <option value="rodent">{t("Rodents")}</option>

                  </select>
              </label>
              <label>
                  {t("Category")}:
                  <select
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                      <option value="all">{t("All")}</option>
                      <option value="vaccines">{t("Vaccines")}</option>
                      <option value="diagnostics">{t("Diagnostics")}</option>
                      <option value="rodenticides">{t("Rodenticides")}</option>
                      <option value="test-kits">{t("Test Kits")}</option>
                      <option
                          value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
                      <option value="experimental-drugs">{t("Experimental Drugs")}</option>

                  </select>
              </label>
              <label>
                  {t("Product Name")}:
                  <input
                      type="text"
                      placeholder={t("Enter product name")}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                  />
              </label>
              <label>
                  {t("Active Ingredient")}:
                  <input
                      type="text"
                      placeholder={t("Enter active ingredient")}
                      value={activeIngredient}
                      onChange={(e) => setActiveIngredient(e.target.value)}
                  />
              </label>
              <label>
                  {t("Pharmaceutical Form")}:
                  <input
                      type="text"
                      placeholder={t("Enter pharmaceutical form")}
                      value={pharmaceuticalForm}
                      onChange={(e) => setPharmaceuticalForm(e.target.value)}
                  />
              </label>
              <button onClick={handleReset} style={{marginBottom: "10px"}}>
                  {t("Reset Search")}
              </button>
              <button onClick={handleSearchClick}>{t("Search")}</button>
          </div>
      </div>
  );
}

export default Sibirka;
