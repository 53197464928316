import React, { useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import "../components/styles/Description.css";
import { useTranslation } from "react-i18next";
import Cat from "../assets/АСВ.jpg";

function VirusVaccinePig() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAnimal, setSelectedAnimal] = useState("all");
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleSearchClick = () => {
    navigate("/menu", {
      state: {
        searchQuery: searchTerm,
        selectedCategory,
        selectedAnimal,
        activeIngredient,
        pharmaceuticalForm,
      },
    });
  };
const handleDownload = () => {
  const pdfPath = "/pdf/pdf_vkladki/ЛИСТІВКА-ВКЛАДКА_ЧУМА_СВИНЕЙ.PDF"; // Correct path to the file
  const link = document.createElement('a');
  link.href = encodeURI(pdfPath);  // Encode the URI to handle special characters
  link.setAttribute('download', pdfPath.split('/').pop()); // Extracts the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
  };

  return (
      <div className="descriptionPage">
        <div className="productDetails">
          <div className="title_image">
            <h1>
              {t(
                  "Virus Vaccine (CSF) from Strain 'K' Against Classical Swine Fever"
              )}
            </h1>
            <img src={Cat} alt="Virus Vaccine" className="productImage"/>
          </div>
          <div className="productExtraInfo">
            <p>
              <strong>{t("Product Name")}:</strong>{" "}
              {t(
                  "Virus Vaccine (CSF) from Strain 'K' Against Classical Swine Fever, Dry, Lappinized"
              )}
            </p>
            <p><strong>{t("Price per vial (100 doses)")}</strong>: 342.00 {t("UAH")}</p>
            <p><strong>{t("Minimum order")}</strong>: 10 {t("vials")}</p>
            <p><strong>{t("Availability")}</strong>: {t("In stock")}</p>
            <h3>{t("Composition")}</h3>
            <p>
              {t(
                  "Virus of classical swine fever, strain 'K' with an infectious activity of no less than 3.5 lg EID50/cm³. Prepared from crushed spleen and lymph nodes of rabbits infected with the production strain of lappinized classical swine fever virus 'K', with the addition of defibrinated virus-containing blood at a ratio of 1:15."
              )}
            </p>

            <h3>{t("Pharmaceutical Form")}</h3>
            <p>{t("Dry mass (lyophilized) of red color.")}</p>

            <h3>{t("Biological Properties")}</h3>
            <p>
              {t(
                  "Immunity levels are monitored by measuring virus-neutralizing antibodies through selective blood serum studies in accordance with the 'Guidelines for Determining the Effectiveness of Vaccination and Immunity Against Classical Swine Fever.' Immunity develops in vaccinated animals by the 7th day after vaccination and lasts up to one year. The vaccine does not possess therapeutic properties."
              )}
            </p>

            <h3>{t("Target Animals")}</h3>
            <p>{t("Pigs.")}</p>

            <h3>{t("Indications for Use")}</h3>
            <p>
              {t(
                  "Used for the preventive and emergency immunization of animals against classical swine fever in farms and areas of concern. During outbreaks, clinically healthy animals may be vaccinated with a 10-fold dose with permission from the State Veterinary Medicine Committee of Ukraine. In areas at risk where vaccination has not been carried out, all animals, starting from one day of age, should be vaccinated. Only clinically healthy animals are vaccinated."
              )}
            </p>
            <ul>
              <li>
                {t(
                    "Sows: Vaccinated 10-15 days before mating (breeding), once a year."
                )}
              </li>
              <li>{t("Boars: Vaccinated once a year.")}</li>
              <li>
                {t(
                    "Piglets: Vaccinated at 40-45 days of age and revaccinated at 85-100 days, then once a year."
                )}
              </li>
            </ul>

            <h3>{t("Contraindications")}</h3>
            <p>
              {t(
                  "Animals with elevated body temperatures are not permitted to be vaccinated. Mandatory animal examinations and thermometry are conducted on the day of vaccination."
              )}
            </p>

            <h3>{t("Precautions for Use")}</h3>
            <p>
              {t(
                  "Dissolve the vaccine in cooled boiled water or sterile saline at a ratio of 2 cm³ per vaccine dose, following aseptic rules. Protect from direct sunlight. Do not use the vaccine past its expiration date or if storage conditions are violated. Vaccinated pigs can be sent to slaughter regardless of vaccination timing, but they can only be moved to other farms 10-12 days after vaccination."
              )}
            </p>
            <p>
              {t(
                  "When planning additional vaccinations, maintain a 14-day interval between the administration of other vaccines."
              )}
            </p>

            <h3>{t("Interaction with Other Products")}</h3>
            <p>
              {t(
                  "It is not recommended to treat animals with antibiotics or antiparasitic agents for 10 days before and after vaccination."
              )}
            </p>

            <h3>{t("Special Instructions for Pregnancy and Lactation")}</h3>
            <p>
              {t(
                  "For preventive vaccination, sows are vaccinated 10-15 days before mating once a year. In areas with outbreaks, sows can be vaccinated regardless of pregnancy stage."
              )}
            </p>

            <h3>{t("Method of Application and Dosage")}</h3>
            <p>
              {t(
                  "Administer 2 cm³ intramuscularly in the neck or inner thigh. Use sterile syringes and needles, sterilizing them by boiling for 30 minutes before and after use."
              )}
            </p>

            <h3>{t("Side Effects")}</h3>
            <p>
              {t(
                  "During 14 days post-vaccination, observe all vaccinated animals. Some healthy animals may experience a temperature rise of up to 1°C on days 3–7 post-vaccination while remaining in good condition and retaining their appetite."
              )}
            </p>
            <p>
              {t(
                  "In unhealthy farms, vaccinated animals in the incubation period of classical swine fever may show symptoms of the disease."
              )}
            </p>

            <h3>{t("Withdrawal Period")}</h3>
            <p>{t("Zero days.")}</p>

            <h3>{t("Special Precautions for Personnel")}</h3>
            <p>
              {t(
                  "In case of accidental self-injection, seek medical attention immediately and provide the vaccine leaflet or label."
              )}
            </p>

            <h3>{t("Disposal and Special Precautions for Personnel")}</h3>
            <p>
              {t(
                  "Destroy vials with broken seals or unutilized vaccine by boiling for 30 minutes or autoclaving at 132°C for 30 minutes."
              )}
            </p>

            <h3>{t("Shelf Life")}</h3>
            <p>{t("12 months. Use within 4 hours of reconstitution.")}</p>

            <h3>{t("Storage and Transportation Conditions")}</h3>
            <p>
              {t(
                  "Store in a dry, dark place at 2–8°C. Transport in compliance with the cold chain requirements."
              )}
            </p>

            <h3>{t("Packaging")}</h3>
            <p>
              {t(
                  "Sterile glass vials of 10 cm³ containing 50, 100, or 200 doses."
              )}
            </p>

            <h3>{t("Manufacturer Information")}</h3>
            <p>
              {t(
                  "State Enterprise 'Sumy Biological Factory', 40021, Sumy, 25 Lykhvynska Stinka St., Ukraine, Tel./Fax: (0542) 61-70-08."
              )}
            </p>
            <h3>{t("Additional Information")}</h3>
            <p>{t("If the product does not meet the requirements of the leaflet or complications arise, stop using the batch immediately and inform the State Scientific Control Institute of Biotechnology and Microorganism Strains (SSCIB) and the supplier (manufacturer). Unopened vials from this batch must be sent to SSCIB, 03151 Kyiv, Donetska Street, 30.")}</p>
            <div className="productDownloadRegistrationCert">
              <button onClick={handleDownload} className="productDownloadRegistrationCert">
                {t("Download Product Registration")}
              </button>
            </div>
            <div className="productDownloadRegistrationCert">
              <Link to="/contact" className="productDownloadRegistrationCert">
                {t("Check availability")}
              </Link>
            </div>
          </div>
        </div>
        <div className="sidebar">
          <h2>{t("Search the Product")}</h2>
          <label>
            {t("Animal")}:
            <select
                value={selectedAnimal}
                onChange={(e) => setSelectedAnimal(e.target.value)}
            >
              <option value="all">{t("another")}</option>

              <option value="pig">{t("Pig")}</option>
              <option value="cattle">{t("Cattle")}</option>

              <option value="poultry">{t("Poultry")}</option>


              <option value="rabbit">{t("Rabbit")}</option>

              <option value="rodent">{t("Rodents")}</option>

            </select>
          </label>
          <label>
            {t("Category")}:
            <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="all">{t("All")}</option>
              <option value="vaccines">{t("Vaccines")}</option>
              <option value="diagnostics">{t("Diagnostics")}</option>
              <option value="rodenticides">{t("Rodenticides")}</option>
              <option value="test-kits">{t("Test Kits")}</option>
              <option
                  value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
              <option value="experimental-drugs">{t("Experimental Drugs")}</option>

            </select>
          </label>
          <label>
            {t("Product Name")}:
            <input
                type="text"
                placeholder={t("Enter product name")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
          </label>
          <label>
            {t("Active Ingredient")}:
            <input
                type="text"
                placeholder={t("Enter active ingredient")}
                value={activeIngredient}
                onChange={(e) => setActiveIngredient(e.target.value)}
            />
          </label>
          <label>
            {t("Pharmaceutical Form")}:
            <input
                type="text"
                placeholder={t("Enter pharmaceutical form")}
                value={pharmaceuticalForm}
                onChange={(e) => setPharmaceuticalForm(e.target.value)}
            />
          </label>
          <button onClick={handleReset} style={{marginBottom: "10px"}}>
            {t("Reset Search")}
          </button>
          <button onClick={handleSearchClick}>{t("Search")}</button>
        </div>
      </div>
  );
}

export default VirusVaccinePig;
