import React, { useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import "../components/styles/Description.css";
import { useTranslation } from "react-i18next";
import Cat from "../assets/УНІВЕРСАЛЬНІ_КОМПЛЕКТИ.jpg";
import Tabl from "../assets/Tabl_Complect.png";

function Universal () {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAnimal, setSelectedAnimal] = useState("all");
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleSearchClick = () => {
    navigate("/menu", {
      state: {
        searchQuery: searchTerm,
        selectedCategory,
        selectedAnimal,
        activeIngredient,
        pharmaceuticalForm,
      },
    });
  };

  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
  };

  return (
      <div className="descriptionPage">
          <div className="productDetails">
              <div className="title_image">
                  <h1>{t("Universal Set of Chemical Reagents")}</h1>
                  <img src={Cat} alt="Analysis Kit" className="productImage"/>
              </div>
              <div className="productExtraInfo">
                  <p>
                      <strong>{t("Product Name")}:</strong>{" "}
                      {t("Universal Set")}
                  </p>
                  <p><strong>{t("Price per unit")}</strong>: 1560.00 {t("UAH")}</p>
                  <p><strong>{t("Minimum order")}</strong>: 1 {t("unit")}</p>
                  <p><strong>{t("Availability")}</strong>: {t("Out of stock")}</p>

                  <p>
                      <strong>{t("Composition of the set:")}:</strong>{" "}

                  </p>
                  <img src={Tabl} alt={t("Test System Procedure")} className="productImageTabl"/>
                  {/*<h3>{t("Analysis Set Composition")}</h3>*/}
                  <p>
                      {t(
                          "The set is designed for the analysis of milk (acidity, reductase test with methylene blue or resazurin, determination of soda and starch adulterants), cream, sour cream, and cheese (acidity, determination of starch adulterants) according to GOST 9225, GOST 24065, and GOST 3624."
                      )}
                  </p>
                  <p>
                      {t(
                          "The set is intended for bacterioscopic examination of meat and meat products (smear staining according to GOST 21237 and GOST 23392), biochemical analysis of meat (peroxidase reaction, formaldehyde reaction, reaction with copper sulfate according to GOST 23392)."
                      )}
                  </p>
                  <p>
                      {t(
                          "The set is designed for honey analysis: determination of total acidity, amylase (diastase) activity, adulterants of flour and starch, according to the 'Veterinary-Sanitary Rules for the Examination of Honey and Beekeeping Products.'"
                      )}
                  </p>
                  <p>
                      {t(
                          "The set is intended for determining the nitrate content in products and feeds of plant origin according to GOST 13496.19."
                      )}
                  </p>
                  <p>
                      {t("The shelf life of the reagent is indicated on each bottle.")}
                  </p>

                  <div className="productDownloadRegistrationCert">
                      <Link to="/contact" className="productDownloadRegistrationCert">
                          {t("Check availability")}
                      </Link>
                  </div>
                  <h3>{t("Download Full Instruction")}</h3>
                  <a href="/pdf/Інструкція.pdf" download="Інструкція.pdf">
                      <button className="download-btnUniversal">{t("Download Instruction")} (PDF)</button>
                  </a>
              </div>
          </div>
          <div className="sidebar">
              <h2>{t("Search the Product")}</h2>
              <label>
                  {t("Animal")}:
                  <select
                      value={selectedAnimal}
                      onChange={(e) => setSelectedAnimal(e.target.value)}
                  >
                      <option value="all">{t("another")}</option>

                      <option value="pig">{t("Pig")}</option>
                      <option value="cattle">{t("Cattle")}</option>

                      <option value="poultry">{t("Poultry")}</option>


                      <option value="rabbit">{t("Rabbit")}</option>

                      <option value="rodent">{t("Rodents")}</option>

                  </select>
              </label>
              <label>
                  {t("Category")}:
                  <select
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                      <option value="all">{t("All")}</option>
                      <option value="vaccines">{t("Vaccines")}</option>
                      <option value="diagnostics">{t("Diagnostics")}</option>
                      <option value="rodenticides">{t("Rodenticides")}</option>
                      <option value="test-kits">{t("Test Kits")}</option>
                      <option
                          value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
                      <option value="experimental-drugs">{t("Experimental Drugs")}</option>

                  </select>
              </label>
              <label>
                  {t("Product Name")}:
                  <input
                      type="text"
                      placeholder={t("Enter product name")}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                  />
              </label>
              <label>
                  {t("Active Ingredient")}:
                  <input
                      type="text"
                      placeholder={t("Enter active ingredient")}
                      value={activeIngredient}
                      onChange={(e) => setActiveIngredient(e.target.value)}
                  />
              </label>
              <label>
                  {t("Pharmaceutical Form")}:
                  <input
                      type="text"
                      placeholder={t("Enter pharmaceutical form")}
                      value={pharmaceuticalForm}
                      onChange={(e) => setPharmaceuticalForm(e.target.value)}
                  />
              </label>
              <button onClick={handleReset} style={{marginBottom: "10px"}}>
                  {t("Reset Search")}
              </button>
              <button onClick={handleSearchClick}>{t("Search")}</button>
          </div>
      </div>
  );
}

export default Universal;
