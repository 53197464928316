import React from 'react';
import { useTranslation } from 'react-i18next';
import "../components/styles/JobPosting.css"; // Assuming the CSS file is named JobPosting.css

const JobPosting = () => {
  const { t } = useTranslation();

  return (
    <div className="job-posting-container">
      <h1>{t('Join Our Team!')}</h1>
      <p>
        {t('Sumy Biofactory is looking for active, professional, and talented individuals who aspire to grow with us. We specialize in producing high-quality products for veterinary medicine, and our team is the most valuable resource of the company.')}
      </p>

      <h2>{t('Why Choose Us?')}</h2>
      <ul>
        <li>{t('Stable official employment.')}</li>
        <li>{t('Comfortable working conditions and modern equipment.')}</li>
        <li>{t('Opportunities for professional growth.')}</li>
        <li>{t('Participation in large-scale innovative projects.')}</li>
      </ul>

      <p>
        {t('If you meet our requirements, send your resume to:')}
        <a href="mailto:biofabrikasumy@gmail.com">biofabrikasumy@gmail.com</a>
      </p>

      <h2>{t('Current Vacancies')}</h2>
      <div className="dir-transport">

      <h3>{t('Warehouse Manager')}</h3>
      <p>
        {t('Sumy Biofactory invites an experienced professional for the responsible position of Warehouse Manager. You will be responsible for the smooth operation of the warehouse, ensuring the storage and accounting of biological preparations and other material assets.')}
      </p>

      <h4>{t('Your Responsibilities:')}</h4>
      <ul>
        <li>{t('Organization and control of the receipt, storage, and release of finished products and raw materials.')}</li>
        <li>{t('Maintaining records of warehouse operations and preparing reports.')}</li>
        <li>{t('Monitoring compliance with temperature and other storage conditions.')}</li>
        <li>{t('Optimizing warehouse processes.')}</li>
        <li>{t('Participation in inventory checks.')}</li>
      </ul>

      <h4>{t('Candidate Requirements:')}</h4>
      <ul>
        <li>{t('Knowledge of warehouse management and storage standards for pharmaceutical products.')}</li>
        <li>{t('Confident PC user (MS Office, 1C).')}</li>
        <li>{t('Responsibility, organization, attention to detail.')}</li>
      </ul>

      <h4>{t('What We Offer:')}</h4>
      <ul>
        <li>{t('Stable work in a socially stable company.')}</li>
        <li>{t('Official employment in accordance with the Labor Code of Ukraine.')}</li>
        <li>{t('Opportunities for professional growth and development.')}</li>
        <li>{t('Competitive salary.')}</li>
        <li>{t('Social guarantees.')}</li>
      </ul>
      </div>
    </div>
  );
};

export default JobPosting;
