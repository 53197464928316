import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReorderIcon from "@mui/icons-material/Reorder";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Logo from "../assets/logoMain.png";
import "./styles/Navbar.css";
import { useTranslation } from "react-i18next";

function Navbar() {
    const [openLinks, setOpenLinks] = useState(false); // For the main menu toggle
    const [dropdownOpen, setDropdownOpen] = useState({}); // Tracks which dropdowns are open
    const dropdownRefs = useRef({}); // To store references to dropdown menus
    const { t, i18n } = useTranslation(); // Access translation and i18n instance
    const [openLanguageMenu, setOpenLanguageMenu] = useState(false);

    const toggleLanguageMenu = () => {
        setOpenLanguageMenu(!openLanguageMenu); // Toggle dropdown visibility
    };

    const changeLanguage = (language) => {
        i18n.changeLanguage(language); // Change the language dynamically
        setOpenLanguageMenu(false); // Close the language dropdown
    };

    // Toggles the navbar for smaller screens
    const toggleNavbar = () => {
        setOpenLinks(!openLinks);
    };

    // Toggles a specific dropdown menu
    const toggleDropdown = (menu) => {
        setDropdownOpen((prevState) => ({
            ...prevState,
            [menu]: !prevState[menu],
        }));
    };

    // Closes all dropdowns when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            let clickedOutside = true;
            Object.values(dropdownRefs.current).forEach((ref) => {
                if (ref && ref.contains(event.target)) {
                    clickedOutside = false;
                }
            });

            if (clickedOutside) {
                setDropdownOpen({});
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Ensures the navbar closes when resizing to a larger screen
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setOpenLinks(false);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Handles menu item clicks (closes the menu and dropdown)
    const handleMenuClick = (menu) => {
        setOpenLinks(false); // Close navbar
        if (menu) setDropdownOpen((prevState) => ({ ...prevState, [menu]: false })); // Close specific dropdown
    };

    return (
        <div className="navbar">
            {/* Left Side */}
            <div className="leftSide">
                <Link to="/" onClick={() => handleMenuClick()}>
                    <img src={Logo} alt="logo" />
                </Link>
            </div>

            {/* Right Side */}
            <div className={`rightSide ${openLinks ? "active" : ""}`}>
                <Link to="/" onClick={() => handleMenuClick()}>
                    {t("Home")}
                </Link>

                {/* About Dropdown */}
                <div
                    className="dropdown"
                    ref={(el) => (dropdownRefs.current["about"] = el)}
                >
                    <button onClick={() => toggleDropdown("about")}>
                        {t("About Us")} <ArrowDropDownIcon sx={{ color: "#F43940" }} />
                    </button>
                    {dropdownOpen["about"] && (
                        <ul className="submenu show">
                            <li>
                                <Link
                                    to="/about/history"
                                    onClick={() => handleMenuClick("about")}
                                >
                                    {t("General Information")}
                                </Link>
                            </li>


                            <li>
                                <Link
                                    to="/about/certificates"
                                    onClick={() => handleMenuClick("about")}
                                >
                                    {t("Product quality")}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/about/vacancies"
                                    onClick={() => handleMenuClick("about")}
                                >
                                    {t("Vacancies")}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/about/news"
                                    onClick={() => handleMenuClick("about")}
                                >
                                    {t("News")}
                                </Link>
                            </li>
                        </ul>
                    )}
                </div>

                {/* Products Link */}
                <Link to="/menu" onClick={() => handleMenuClick()}>
                    {t("Products")}
                </Link>

                {/* Contacts Dropdown */}
                <div
                    className="dropdown"
                    ref={(el) => (dropdownRefs.current["contacts"] = el)}
                >
                    <button onClick={() => toggleDropdown("contacts")}>
                        {t("Contacts")} <ArrowDropDownIcon sx={{ color: "#F43940" }} />
                    </button>
                    {dropdownOpen["contacts"] && (
                        <ul className="submenu show">
                            <li>
                                <Link
                                    to="/contacts/addresses"
                                    onClick={() => handleMenuClick("contacts")}
                                >
                                    {t("Addresses")}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/contacts/map"
                                    onClick={() => handleMenuClick("contacts")}
                                >
                                    {t("Directions")}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/contact"
                                    onClick={() => handleMenuClick("contacts")}
                                >
                                    {t("Feedback Form")}
                                </Link>
                            </li>
                        </ul>
                    )}
                </div>

                <Link to="/payment" onClick={() => handleMenuClick()}>
                    {t("Payment & Delivery")}
                </Link>
                <Link to="/exchange" onClick={() => handleMenuClick()}>
                    {t("Exchange & Returns")}
                </Link>

                {/* Recommendations Dropdown */}
                <div
                    className="dropdown"
                    ref={(el) => (dropdownRefs.current["recommendations"] = el)}
                >
                    <button onClick={() => toggleDropdown("recommendations")}>
                        {t("Recommendations")} <ArrowDropDownIcon sx={{ color: "#F43940" }} />
                    </button>
                    {dropdownOpen["recommendations"] && (
                        <ul className="submenu show">
                            <li>
                                <Link
                                    to="/recommendations/collaboration"
                                    onClick={() => handleMenuClick("recommendations")}
                                >
                                    {t("Collaboration")}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/recommendations/reviews"
                                    onClick={() => handleMenuClick("recommendations")}
                                >
                                    {t("Reviews")}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/recommendations/tips"
                                    onClick={() => handleMenuClick("recommendations")}
                                >
                                    {t("Advice & Tips")}
                                </Link>
                            </li>
                        </ul>
                    )}
                </div>

                <div className="languageSwitcher">
                    <button onClick={toggleLanguageMenu}>
                        {i18n.language.toUpperCase()} <ArrowDropDownIcon sx={{ color: "#F43940" }} />
                    </button>
                    <ul className={`submenu ${openLanguageMenu ? "show" : ""}`}>
                        <li>
                            <button onClick={() => changeLanguage("en")}>EN</button>
                        </li>
                        <li>
                            <button onClick={() => changeLanguage("ukr")}>UKR</button>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Hamburger Icon for Smaller Screens */}
            <button className="reorder" onClick={toggleNavbar}>
                <ReorderIcon />
            </button>
        </div>
    );
}

export default Navbar;
