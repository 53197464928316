import React, { useState } from "react";
import Mansion from "../assets/mansion.jpg";
import "../components/styles/Collab.css";
import { useTranslation } from "react-i18next"; // Import useTranslation
import axios from "axios"; // Import axios for HTTP requests

function Collab() {
    const { t } = useTranslation(); // Access translation function
    const [formData, setFormData] = useState({ name: "", phone: "" }); // State for form data
    const [message, setMessage] = useState(""); // State for success/error messages

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Send form data to backend
            const response = await axios.post("http://localhost:5000/send-phone-details", {
                name: formData.name,
                message: formData.phone, // Sending phone number as "message" based on backend structure
            });

           alert(t("Message sent successfully!"));
            setFormData({ name: "", phone: "" }); // Clear form after success
        } catch (error) {
            console.error("Error sending contact details:", error);
            setMessage(t("Failed to send your request. Please try again."));
        }
    };

    return (
        <div className="collaboration">
            <h2>{t("Cooperation")}</h2>
            <strong>{t("Would you like to cooperate with us?")}</strong>
            <p>{t("State Enterprise 'Sumy Biological Factory' is always open to new partnerships and cooperation. We offer a wide range of services and products in the field of biotechnology.")}</p>

            <h3>{t("Why cooperate with us?")}</h3>
            <ul>
                <li>{t("Experience and expertise: Our many years of experience and highly qualified staff enable us to provide high-quality products and services.")}</li>
                <li>{t("Modern equipment: We use modern equipment and technologies that allow us to maintain the highest production standards.")}</li>
                <li>{t("Individual approach: We are ready to develop individual solutions for each client.")}</li>
                <li>{t("Flexible cooperation conditions: We offer various forms of cooperation and are ready to discuss individual terms.")}</li>
            </ul>

            <h3>{t("Possible cooperation areas:")}</h3>
            <ul>
                <li>{t("Product supply: We offer a range of biological products for various industries.")}</li>
                <li>{t("Joint research: We are ready to collaborate with research institutions and companies on the development of new products and technologies.")}</li>
                <li>{t("Contract manufacturing: We offer contract manufacturing services for biological products.")}</li>
            </ul>

            <strong>{t("To discuss the details of cooperation and agree on all terms, please contact our manager at the following phone numbers:")}</strong>

            <ul>
                <li>{t("(068) 420-35-45")}</li>
                <li>{t("(073) 420-35-45")}</li>
                <li>{t("(099) 420-35-45")}</li>
            </ul>

            <strong> {t("We are ready to answer all your questions and offer the best cooperation terms!")}</strong>


            <div className="contact-form_Collab">
                <form className={"collab_form"} id="contact-form" onSubmit={handleSubmit}>
                    <label htmlFor="name">{t("Full Name")}</label>
                    <input
                        name="name"
                        placeholder={t("Enter full name...")}
                        type="text"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />

                    <label htmlFor="phone">{t("Phone Number")}</label>
                    <input
                        name="phone"
                        placeholder={t("Enter phone number...")}
                        type="tel"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                   <button className={"call-button"} type="submit">{t("Order a call")}</button>
        </form>
            </div>
        </div>

);
}

export default Collab;
