import React from "react";
import { useTranslation } from "react-i18next";
import "../components/styles/Adresses.css";
import factoryPhoto from "../assets/adresses.jpg"; // Replace with the correct path to your image

const ContactInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="contact-info-page">
      <h1 className="contact-title">{t("contactInfo.title")}</h1>
      <p className="contact-intro">{t("contactInfo.intro")}</p>

      {/* Phones Section */}
      <section className="contact-section">
        <h2 className="contact-section-title">{t("contactInfo.phones.title")}</h2>
        <ul className="contact-list">
          <li>📞 +38 (099) 420-35-45 — Viber</li>
          <li>📞 +38 (068) 420-35-45</li>
          <li>📞 +38 (073) 420-35-45 — Viber</li>
          <li>📞 +38 (0542) 61-70-08</li>
        </ul>
      </section>

      {/* Email Section */}
      <section className="contact-section">
        <h2 className="contact-section-title">{t("contactInfo.email.title")}</h2>
        <p>📧 <a href="mailto:sgbiofabrika@gmail.com">sgbiofabrika@gmail.com</a></p>
      </section>

      {/* Social Media Section */}
      <section className="contact-section">
        <h2 className="contact-section-title">{t("contactInfo.socialMedia.title")}</h2>
        <p>
          <a href="https://www.facebook.com/sumy.biofabrika/" target="_blank" rel="noopener noreferrer">
            {t("contactInfo.socialMedia.facebook")}
          </a>
        </p>
      </section>

      {/* Working Hours Section */}
      <section className="contact-section">
        <h2 className="contact-section-title">{t("contactInfo.workingHours.title")}</h2>
        <ul className="contact-list">
          <li>{t("contactInfo.workingHours.list.0")}</li>
          <li>{t("contactInfo.workingHours.list.1")}</li>
        </ul>
      </section>

      {/* Address Section */}
      <section className="contact-section">
        <h2 className="contact-section-title">{t("contactInfo.address.title")}</h2>
        <p>{t("contactInfo.address.addressText")}</p>
        <img src={factoryPhoto} alt={t("contactInfo.address.imageAlt")} className="contact-photo" />
      </section>
    </div>
  );
};

export default ContactInfo;
