import React, { useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import "../components/styles/Description.css";
import { useTranslation } from "react-i18next";
import Cat from "../assets/ТУБЕРКУЛІН ДЛЯ ССАВЦІВ1.jpg";

function Tuberkulin() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAnimal, setSelectedAnimal] = useState("all");
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleSearchClick = () => {
    navigate("/menu", {
      state: {
        searchQuery: searchTerm,
        selectedCategory,
        selectedAnimal,
        activeIngredient,
        pharmaceuticalForm,
      },
    });
  };

  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
  };
const handleDownload = () => {
  const pdfPath = "/pdf/pdf_vkladki/ЛИСТІВКА-ВКЛАДКА_ТУБ_ССАВЦІ.PDF"; // Correct path to the file
  const link = document.createElement('a');
  link.href = encodeURI(pdfPath);  // Encode the URI to handle special characters
  link.setAttribute('download', pdfPath.split('/').pop()); // Extracts the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  return (
      <div className="descriptionPage">
          <div className="productDetails">
              <div className="title_image">
                  <h1>{t("Tuberculin")}</h1>
                  <img src={Cat} alt="Tuberculin" className="productImage"/>
              </div>
              <div className="productExtraInfo">
                  <p>
                      <strong>{t("Product Name")}:</strong>{" "}
                      {t("Purified Tuberculin (PPD) for Mammals in Standard Solution")}
                  </p>
                  <div>
                      <p><strong>{t("Price per vial (20 doses)")}</strong>: 146.04 {t("UAH")}</p>
                      <p><strong>{t("Minimum order")}</strong>: 1 {t("vial")}</p>
                      <p><strong>{t("Availability")}</strong>: {t("Out of stock")}</p>
                  </div>
                  <div>
                      <p><strong>{t("Price per vial (50 doses)")}</strong>: 353.10 {t("UAH")}</p>
                      <p><strong>{t("Minimum order")}</strong>: 1 {t("vial")}</p>
                      <p><strong>{t("Availability")}</strong>: {t("In stock")}</p>
                  </div>
                  <div>
                      <p><strong>{t("Price per vial (100 doses)")}</strong>: 680.40 {t("UAH")}</p>
                      <p><strong>{t("Minimum order")}</strong>: 1 {t("vial")}</p>
                      <p><strong>{t("Availability")}</strong>: {t("In stock")}</p>
                  </div>
                  <h3>{t("Composition")}</h3>
                  <ul>
                      <li>{t("Made from the culture filtrate of the production strain 'M. bovis IEKVM-1' grown in synthetic nutrient medium.")}</li>
                      <li>{t("Contains Sodium Chloride (0.85±0.05)%")}</li>
                      <li>{t("Contains Glycerin (10.0±1.0)%")}</li>
                      <li>{t("Contains Phenol (0.3±0.1)%")}</li>
                      <li>{t("Active Component (Total Protein): 0.8±0.2 mg/cm³")}</li>
                      <li>{t("Biological Activity: 50,000±10,000 IU/mg")}</li>
                  </ul>

                  <h3>{t("Pharmaceutical Form")}</h3>
                  <p>{t("Liquid.")}</p>

                  <h3>{t("Biological Properties")}</h3>
                  <p>{t("When injected intradermally, a cellular infiltrate forms in the skin within a few hours, reaching maximum development at 72 hours, and gradually disappearing thereafter (in case of positive reaction).")}</p>

                  <h3>{t("Animal Species")}</h3>
                  <p>{t("Cattle, horses, yaks, buffalo, pigs, sheep, goats, primates, dogs, cats, deer, fur-bearing animals.")}</p>

                  <h3>{t("Indications for Use")}</h3>
                  <p>{t("For in vivo allergic diagnosis of tuberculosis in animals via intradermal injection or ophthalmic test.")}</p>

                  <h3>{t("Contraindications")}</h3>
                  <p>{t("Do not use on animals within 21 days after vaccinations against infectious diseases, anthelmintic treatments, or on debilitated and sick animals in tuberculosis-free farms. Also, do not use on female goats, sheep, sows, mares, or female fur animals within 30 days after childbirth.")}</p>

                  <h3>{t("Precautions for Use")}</h3>
                  <p>{t("Do not use if the vial is damaged, contains mechanical impurities, or if the color has changed.")}</p>

                  <h3>{t("Interaction with Other Products")}</h3>
                  <p>{t("None established.")}</p>

                  <h3>{t("Method of Administration and Dosage")}</h3>
                  <p>{t("Tuberculin is administered intradermally or via ophthalmic test.")}</p>
                  <ul>
                      <li>{t("For cattle: inject into the middle third of the neck or under the tail fold.")}</li>
                      <li>{t("For young cattle: inject into the middle third of the neck or middle part of the shoulder.")}</li>
                      <li>{t("For bulls: inject under the tail fold.")}</li>
                      <li>{t("For pigs: inject into the external surface of the ear, 2 cm from the base, or into the skin over the loins (5-6 cm from the spine).")}</li>
                      <li>{t("For sheep, goats, fur-bearing animals: intrapalpebral injection into the lower eyelid.")}</li>
                      <li>{t("For dogs, cats: inject into the inner thigh skin.")}</li>
                  </ul>

                  <p>{t("Before administering, depilate and treat the skin at the injection site with a 70% ethanol solution. Do not inject into damaged skin.")}</p>

                  <h3>{t("Dosage")}</h3>
                  <ul>
                      <li>{t("For cattle, buffalo, yaks: 5000 IU in 0.1 cm³")}</li>
                      <li>{t("For other species: 10,000 IU in 0.2 cm³")}</li>
                  </ul>

                  <h3>{t("Side Effects")}</h3>
                  <p>{t("None established.")}</p>

                  <h3>{t("Shelf Life")}</h3>
                  <p>{t("2 years from the manufacturing date, use within the same day after opening the vial.")}</p>

                  <h3>{t("Storage Conditions")}</h3>
                  <p>{t("Store in the manufacturer's packaging in dry, closed rooms at 4-8 ºC.")}</p>

                  <h3>{t("Packaging")}</h3>
                  <p>{t("Glass vials: 10, 20 cm³ or ampoules: 2, 5 cm³.")}</p>


                  <h3>{t("Dispensing rules")}</h3>
                  <p>{t("Over the counter.")}</p>
                  <h3>{t("Manufacturer")}</h3>
                  <p>{t("State Enterprise 'Sumy Biological Factory,' Sumy, Likhvinska Stinka St. 25, Ukraine.")}</p>

                  <h3>{t("Additional Information")}</h3>
                  <p>{t("If the product does not meet the requirements or complications arise, immediately stop using the batch and inform the State Scientific Control Institute of Biotechnology and Microorganism Strains.")}</p>
                  <div className="productDownloadRegistrationCert">
                      <button onClick={handleDownload} className="productDownloadRegistrationCert">
                          {t("Download Product Registration")}
                      </button>
                  </div>
                  <div className="productDownloadRegistrationCert">
                      <Link to="/contact" className="productDownloadRegistrationCert">
                          {t("Check availability")}
                      </Link>
                  </div>
              </div>
          </div>
          <div className="sidebar">
              <h2>{t("Search the Product")}</h2>
              <label>
                  {t("Animal")}:
                  <select
                      value={selectedAnimal}
                      onChange={(e) => setSelectedAnimal(e.target.value)}
                  >
                      <option value="all">{t("another")}</option>

                      <option value="pig">{t("Pig")}</option>
                      <option value="cattle">{t("Cattle")}</option>

                      <option value="poultry">{t("Poultry")}</option>


                      <option value="rabbit">{t("Rabbit")}</option>

                      <option value="rodent">{t("Rodents")}</option>

                  </select>
              </label>
              <label>
                  {t("Category")}:
                  <select
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                      <option value="all">{t("All")}</option>
                      <option value="vaccines">{t("Vaccines")}</option>
                      <option value="diagnostics">{t("Diagnostics")}</option>
                      <option value="rodenticides">{t("Rodenticides")}</option>
                      <option value="test-kits">{t("Test Kits")}</option>
                      <option
                          value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
                      <option value="experimental-drugs">{t("Experimental Drugs")}</option>

                  </select>
              </label>
              <label>
                  {t("Product Name")}:
                  <input
                      type="text"
                      placeholder={t("Enter product name")}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                  />
              </label>
              <label>
                  {t("Active Ingredient")}:
                  <input
                      type="text"
                      placeholder={t("Enter active ingredient")}
                      value={activeIngredient}
                      onChange={(e) => setActiveIngredient(e.target.value)}
                  />
              </label>
              <label>
                  {t("Pharmaceutical Form")}:
                  <input
                      type="text"
                      placeholder={t("Enter pharmaceutical form")}
                      value={pharmaceuticalForm}
                      onChange={(e) => setPharmaceuticalForm(e.target.value)}
                  />
              </label>
              <button onClick={handleReset} style={{marginBottom: "10px"}}>
                  {t("Reset Search")}
              </button>
              <button onClick={handleSearchClick}>{t("Search")}</button>
          </div>
      </div>
  );
}

export default Tuberkulin;
